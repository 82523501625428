<template>
  <td>
    <v-layout>
      <DefaultButton
        icon
        small
        @click.prevent="() => onClick('download')"
      >
        <v-icon small>mdi-download</v-icon>
      </DefaultButton>
      <DefaultButton
        icon
        small
        v-if="item.type == 0 && !readonly && item.editable"
        @click.prevent="() => onClick('edit')"
      >
        <v-icon small>mdi-pencil</v-icon>
      </DefaultButton>
      <DefaultButton
        v-if="!readonly && item.editable"
        icon
        small
        @click.prevent="() => onClick('delete')"
        bgColor="error"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>
      </DefaultButton>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {},
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  methods: {
    onClick(action) {
      this.$emit("action", { item: this.item, action: action });
    },
  },
};
</script>
